import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import { UIContext } from "../../../contexts/UIContext";
import { DataContext } from "../../../contexts/DataContext";
import { Link } from "react-router-dom";
import HyperLogo from "../../../Images/HyperLogo.png";
import { UserContext } from "../../../contexts/UserContext";

const Main = styled.div``;

const Content = styled.div`
  padding-left: 10px;
  padding-right: 10px;
  position: fixed;
  top: 60px;
  left: ${(props) => (props.expanded ? "0px" : "-230px")};
  bottom: 0;
  z-index: 100;
  width: 230px;
  background-color: #1d2939;
  transition: all 0.2s ease-in-out;
  padding-top: 40px;
`;

const Ul = styled.ul`
  margin: 0;

  list-style-type: none;
  padding-left: 0;
  transition: all 0.2s ease-in-out;
`;
const Li = styled.li`
  margin-top: 1px;
  > ul {
    margin-left: 0.5em;
  }
`;
const A = styled(Link)`
  display: flex;
  align-items: center;
  border-radius: 0.25rem;
  color: ${(props) =>
    props.selected ? props.theme.highlightColour : "#adb5bd"};
  background-color: ${(props) => (props.selectedbg ? "#141d28" : "")};
  cursor: pointer;
  display: flex;
  line-height: 20px;
  justify-content: space-between;
  text-decoration: none;
  margin: 0;
  padding: 0.375rem 1rem;
  font-weight: ${(props) => (props.bold ? 500 : 300)};
  :hover {
    background-color: #141d28;
    color: #fff;
  }
`;

const A_STATIC = styled.div`
  display: flex;
  align-items: center;
  border-radius: 0.25rem;
  color: ${(props) =>
    props.selected ? props.theme.highlightColour : "#adb5bd"};
  background-color: ${(props) => (props.selectedbg ? "#141d28" : "")};
  cursor: pointer;
  display: flex;
  line-height: 20px;
  justify-content: space-between;
  text-decoration: none;
  margin: 0;
  padding: 0.375rem 1rem;
  font-weight: ${(props) => (props.bold ? 500 : 300)};
  :hover {
    background-color: #141d28;
    color: #fff;
  }
`;

const LogoContainer = styled.div`
  position: fixed;
  top: 0;
  left: ${(props) => (props.expanded ? "0px" : "-230px")};
  width: 230px;
  height: 60px;
  background-color: #141c27;
  border-right: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: 0 1px 4px 0px rgba(0, 0, 0, 0.16);
  font-size: 24px;
  font-weight: 700;
  padding: 0 20px;
  display: flex;
  align-items: center;
  z-index: 1030;
  transition: all 0.2s ease-in-out;
  justify-content: center;
`;
const Logo = styled.img`
  height: 20px;
`;
export default function Sidebar() {
  const { navigationView, setNavigationView, showSideBar } =
    useContext(UIContext);
  const { user } = useContext(UserContext);

  useEffect(() => {
    if (
      user &&
      !user?.features?.find((f) => f.name === "rugby") &&
      window.location.toString().toUpperCase().indexOf("FOOTBALL") === -1
    ) {
      document.location = window.location.origin + "/football";
    }
  }, [user]);

  return (
    <Main>
      <LogoContainer expanded={showSideBar}>
        <Logo src={HyperLogo} />
      </LogoContainer>
      <Content expanded={showSideBar}>
        <Ul>
          {user?.features?.find((f) => f.name === "football") && (
            <Li>
              <A_STATIC
                href="#"
                bold="true"
                onClick={() => {
                  document.location = window.location.origin + "/football";
                }}
              >
                Football
                {/* <FiChevronDown /> */}
              </A_STATIC>
            </Li>
          )}
          {user?.features?.find((f) => f.name === "gaa") && (
            <Li>
              <A_STATIC
                href="#"
                bold="true"
                onClick={() => {
                  document.location = "https://gaa-manager.hyper.live/";
                }}
              >
                GAA
                {/* <FiChevronDown /> */}
              </A_STATIC>
            </Li>
          )}
          {user?.features?.find((f) => f.name === "basketball") && (
            <Li>
              <A_STATIC
                href="#"
                bold="true"
                onClick={() => {
                  document.location = window.location.origin + "/basketball";
                }}
              >
                Basketball
                {/* <FiChevronDown /> */}
              </A_STATIC>
            </Li>
          )}
          <Li>
            <A
              href="#"
              bold="true"
              selected={
                true ||
                (navigationView && navigationView.split("/")[0] === "Rugby")
              }
              onClick={() => {
                setNavigationView("Rugby/");
              }}
              to={""}
            >
              Rugby
              {/* <FiChevronDown /> */}
            </A>
            {/* {navigationView && navigationView.split("/")[0] === "Football" && ( */}
            <Ul>
              <Li
                onClick={() => {
                  setNavigationView("Rugby/Fixtures");
                }}
              >
                <A
                  to="/Rugby/Fixtures"
                  selected={
                    navigationView && navigationView === "Rugby/Fixtures"
                  }
                  selectedbg={
                    navigationView && navigationView === "Rugby/Fixtures"
                  }
                >
                  Fixtures
                </A>
              </Li>
              <Li
                onClick={() => {
                  setNavigationView("Rugby/Teams");
                }}
              >
                <A
                  to="/Rugby/Teams"
                  selected={navigationView && navigationView === "Rugby/Teams"}
                  selectedbg={
                    navigationView && navigationView === "Rugby/Teams"
                  }
                >
                  Teams
                </A>
              </Li>
              {/* <Li
                onClick={() => {
                  setNavigationView("Rugby/Players");
                }}
              >
                <A
                  to="/Rugby/Players"
                  selected={
                    navigationView && navigationView === "Rugby/Players"
                  }
                  selectedBG={
                    navigationView && navigationView === "Rugby/Players"
                  }
                >
                  Players
                </A>
              </Li> */}
              <Li
                onClick={() => {
                  setNavigationView("Rugby/Stadiums");
                }}
              >
                <A
                  to="/Rugby/Stadiums"
                  selected={
                    navigationView && navigationView === "Rugby/Stadiums"
                  }
                  selectedbg={
                    navigationView && navigationView === "Rugby/Stadiums"
                  }
                >
                  Stadiums
                </A>
              </Li>
              <Li
                onClick={() => {
                  setNavigationView("Rugby/Officials");
                }}
              >
                <A
                  to="/Rugby/Officials"
                  selected={
                    navigationView && navigationView === "Rugby/Officials"
                  }
                  selectedbg={
                    navigationView && navigationView === "Rugby/Officials"
                  }
                >
                  Officials
                </A>
              </Li>
              {/* <Li
                onClick={() => {
                  setNavigationView("Rugby/Transfers");
                }}
              >
                <A
                  to="/Rugby/Transfers"
                  selected={
                    navigationView && navigationView === "Rugby/Transfers"
                  }
                  selectedBG={
                    navigationView && navigationView === "Rugby/Transfers"
                  }
                >
                  Transfers
                </A>
              </Li> */}
              <Li
                onClick={() => {
                  setNavigationView("Rugby/Countries");
                }}
              >
                <A
                  to="/Rugby/Countries"
                  selected={
                    navigationView && navigationView === "Rugby/Countries"
                  }
                  selectedbg={
                    navigationView && navigationView === "Rugby/Countries"
                  }
                >
                  Countries
                </A>
              </Li>
              <Li
                onClick={() => {
                  setNavigationView("Rugby/Competitions");
                }}
              >
                <A
                  to="/Rugby/Competitions"
                  selected={
                    navigationView && navigationView === "Rugby/Competitions"
                  }
                  selectedbg={
                    navigationView && navigationView === "Rugby/Competitions"
                  }
                >
                  Competitions
                </A>
              </Li>
              <Li
                onClick={() => {
                  setNavigationView("Rugby/Seasons");
                }}
              >
                <A
                  to="/Rugby/Seasons"
                  selected={
                    navigationView && navigationView === "Rugby/Seasons"
                  }
                  selectedbg={
                    navigationView && navigationView === "Rugby/Seasons"
                  }
                >
                  Seasons
                </A>
              </Li>
              <Li
                onClick={() => {
                  setNavigationView("Rugby/Import");
                }}
              >
                <A
                  to="/Rugby/Import"
                  selected={navigationView && navigationView === "Rugby/Import"}
                  selectedbg={
                    navigationView && navigationView === "Rugby/Import"
                  }
                >
                  Import
                </A>
              </Li>
              {/* <Li
                onClick={() => {
                  setNavigationView("Rugby/Formations");
                }}
              >
                <A
                  to="/Rugby/Formations"
                  selected={
                    navigationView && navigationView === "Rugby/Formations"
                  }
                  selectedbg={
                    navigationView && navigationView === "Rugby/Formations"
                  }
                >
                  Formations
                </A>
              </Li> */}
            </Ul>
            {/* )} */}
          </Li>
          {user?.roles?.findIndex((i) => i == "admin" || i == "support") >
            -1 && (
            <Li>
              <A
                href="#"
                bold="true"
                selected={
                  true ||
                  (navigationView && navigationView.split("/")[0] === "System")
                }
                onClick={() => {
                  setNavigationView("System/");
                }}
              >
                System Support
                {/* <FiChevronDown /> */}
              </A>
              <Ul>
                <Li
                  onClick={() => {
                    setNavigationView("System/opta");
                  }}
                >
                  <A
                    to="/System/opta"
                    selected={
                      navigationView && navigationView === "System/opta"
                    }
                    selectedbg={
                      navigationView && navigationView === "System/opta"
                    }
                  >
                    OPTA
                  </A>
                </Li>
                <Li
                  onClick={() => {
                    setNavigationView("System/monitoring");
                  }}
                >
                  <A
                    to="/System/monitoring"
                    selected={
                      navigationView && navigationView === "System/monitoring"
                    }
                    selectedbg={
                      navigationView && navigationView === "System/monitoring"
                    }
                  >
                    Monitoring
                  </A>
                </Li>

                <Li
                  onClick={() => {
                    setNavigationView("System/logs");
                  }}
                >
                  <A
                    to="/System/logs"
                    selected={
                      navigationView && navigationView === "System/logs"
                    }
                    selectedbg={
                      navigationView && navigationView === "System/logs"
                    }
                  >
                    Logs
                  </A>
                </Li>
                <Li
                  onClick={() => {
                    setNavigationView("System/viz");
                  }}
                >
                  <A
                    to="/System/viz"
                    selected={navigationView && navigationView === "System/viz"}
                    selectedbg={
                      navigationView && navigationView === "System/viz"
                    }
                  >
                    Viz Engines
                  </A>
                </Li>
                <Li
                  onClick={() => {
                    setNavigationView("System/Users");
                  }}
                >
                  <A
                    to="/System/Users"
                    selected={
                      navigationView && navigationView === "System/Users"
                    }
                    selectedbg={
                      navigationView && navigationView === "System/Users"
                    }
                  >
                    Users
                  </A>
                </Li>
                <Li
                  onClick={() => {
                    setNavigationView("System/Database");
                  }}
                >
                  <A
                    to="/System/Database"
                    selected={
                      navigationView && navigationView === "System/Database"
                    }
                    selectedbg={
                      navigationView && navigationView === "System/Database"
                    }
                  >
                    Database
                  </A>
                </Li>
                <Li
                  onClick={() => {
                    setNavigationView("System/Config");
                  }}
                >
                  <A
                    to="/System/Config"
                    selected={
                      navigationView && navigationView === "System/Config"
                    }
                    selectedbg={
                      navigationView && navigationView === "System/Config"
                    }
                  >
                    Config
                  </A>
                </Li>
                <Li
                  onClick={() => {
                    setNavigationView("System/Settings");
                  }}
                >
                  <A
                    to="/System/Settings"
                    selected={
                      navigationView && navigationView === "System/Settings"
                    }
                    selectedbg={
                      navigationView && navigationView === "System/Settings"
                    }
                  >
                    Settings
                  </A>
                </Li>
              </Ul>
            </Li>
          )}
        </Ul>
      </Content>
    </Main>
  );
}
