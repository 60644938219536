import React, { useContext, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
} from "react-router-dom";
import { ThemeProvider } from "styled-components";
import Main from "./components/Views/Main/Main";
import Hyper from "./Theme/Hyper";
import { DataProvider } from "./contexts/DataContext";
import { UIProvider } from "./contexts/UIContext";
import { SocketProvider } from "./contexts/SocketContext";
import Seasons from "./components/Views/Seasons";
import Fixtures from "./components/Views/Fixtures";
import Officials from "./components/Views/Officials";
import Countries from "./components/Views/Countries";
import Stadiums from "./components/Views/Stadiums";
import Login from "./components/Authentication/Login";
import { UserContext, UserProvider } from "./contexts/UserContext";
import Competitions from "./components/Views/Competitions";
import Fixture from "./components/Fixture/Fixture";
import gql from "graphql-tag";
import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  useQuery,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import Teams from "./components/Views/Teams";
import DataImport from "./components/Views/DataImport";

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("token");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `${token}` : "",
    },
  };
});

const httpLink = createHttpLink({
  uri:
    (window.ENV?.REACT_APP_PROJECT_SERVER ||
      process.env.REACT_APP_PROJECT_SERVER) + "/graphql",
});

const user_client = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(httpLink),
});

const LOGGED_IN = gql`
  query LoggedIn {
    me {
      username
      token
    }
  }
`;
function App() {
  return (
    <div className="App">
      <Router basename={process.env.PUBLIC_URL}>
        <ThemeProvider theme={Hyper}>
          <UserProvider>
            <UIProvider>
              <DataProvider>
                <SocketProvider>
                  <Routes />
                </SocketProvider>
              </DataProvider>
            </UIProvider>
          </UserProvider>
        </ThemeProvider>
      </Router>
    </div>
  );
}

function Routes() {
  const { user, setUserToken } = useContext(UserContext);
  const {
    loading,
    error,
    data,
    refetch: refetchLogin,
  } = useQuery(LOGGED_IN, {
    fetchPolicy: "network-first",
    client: user_client,
  });

  useEffect(() => {
    if (data) {
      setUserToken(data?.me?.token);
    }
  }, [data]);

  return (
    <>
      {!data?.me?.token && (
        <Login
          loggedIn={() => {
            refetchLogin();
          }}
        />
      )}
      {data?.me?.token && (
        <Switch>
          <Route exact path="/">
            <Redirect to="/rugby/fixtures" />
          </Route>
          <Route path="/rugby/teams">
            <Main>
              <Teams />
            </Main>
          </Route>
          {/* <Route path="/rugby/players">
            <Main>
              <Players />
            </Main>
          </Route> */}
          <Route path="/rugby/fixtures/:fixtureId">
            <Main>
              <Fixture />
            </Main>
          </Route>
          <Route path="/rugby/fixtures">
            <Main>
              <Fixtures />
            </Main>
          </Route>
          <Route path="/season/:season">
            <Main>
              <Fixtures />
            </Main>
          </Route>
          <Route path="/rugby/competitions">
            <Main>
              <Competitions />
            </Main>
          </Route>
          <Route path="/competition/:comp">
            <Main>
              <Seasons />
            </Main>
          </Route>
          <Route path="/rugby/seasons">
            <Main>
              <Seasons />
            </Main>
          </Route>
          <Route path="/rugby/countries">
            <Main>
              <Countries />
            </Main>
          </Route>
          <Route path="/rugby/officials">
            <Main>
              <Officials />
            </Main>
          </Route>
          <Route path="/rugby/stadiums">
            <Main>
              <Stadiums />
            </Main>
          </Route>
          <Route path="/rugby/import">
            <Main>
              <DataImport />
            </Main>
          </Route>
          <Route path="/">
            <Main />
          </Route>
        </Switch>
      )}
    </>
  );
}
export default App;
