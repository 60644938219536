import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { DataContext } from "../../contexts/DataContext";
import { UIContext } from "../../contexts/UIContext";
import usePrevious from "../../hooks/usePrevious";
import FormationEditor from "../Formation/FormationEditor";
import Formation from "./Formation";
import BarLoader from "react-spinners/BarLoader";
import PlayerEditor from "../Modals/PlayerEditor";
import { Prompt } from "react-router";

const Main = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Row = styled.div`
  display: flex;
  width: 100%;
`;
export const Button = styled.div`
  display: flex;
  margin-right: 1em;
  min-width: 80px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  align-items: center;
  padding: 0.5em;
  border-radius: 4px;
  justify-content: center;
  cursor: pointer;
  box-shadow: "0 1px 2px 0 rgba(0, 0, 0, 0.1)";
  background-color: ${(props) =>
    props.green ? "#14ec8e" : props.red ? "#db0a41" : "#dee2e6"};
  color: ${(props) => (props.green || props.red ? "#fff" : "")};
  font-size: 0.8em;
  :hover {
    cursor: pointer;
    background-color: ${(props) =>
      props.green ? "#0ed47e" : props.red ? "#b10835" : "#dadcde"};
  }
`;

let formation_global = true;
export default function LineupEditor({ team, fixture, onChange, game }) {
  const [lineup, setLineup] = useState([]);
  const [squad, setSquad] = useState([]);
  const [teamObj, setTeamObj] = useState([]);
  const [selectedPlayer, setSelectedPlayer] = useState();
  const [changed, setChanged] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedFormation, setSelectedFormation] = useState();

  const { getSquad, getTeams, updateGameFormation } = useContext(DataContext);
  const { selectedSeason } = useContext(UIContext);
  const prevTeam = usePrevious({ team });

  useEffect(() => {
    if (fixture && team !== prevTeam) {
      getData();
    }
  }, [team, fixture]);

  useEffect(() => {
    setSelectedFormation();
    //setLoading(true);
    formation_global = null;
    setChanged(false);

    let l = fixture?.lineup?.[team];
    setLineup([...(l || [])]);
  }, [fixture, team]);

  function getData() {
    if (fixture && team) {
      setTeamObj(fixture[`${team}_team`]);

      if (lineup && prevTeam?.team !== team) {
        lineup.length = 0;
      }
      if (squad && prevTeam?.team !== team) {
        squad.length = 0;
      }

      getSquad({
        teamId: fixture[`${team}_team`]._id,
        seasonId: fixture.season._id,
      }).then((data) => {
        formation_global = null;

        setSquad([...(data || [])]);
        //setLoading(false);
        setChanged(false);
      });
    }
  }

  function onSave() {
    onChange(lineup);
  }

  function updateFormation(formation) {
    if (formation_global) {
      setChanged(true);
    }
    formation_global = formation;
    setSelectedFormation(formation);
  }

  function updateLineup(lineup) {
    setChanged(true);
    setLineup(lineup);
  }

  return (
    <>
      <Prompt
        when={changed}
        message="You have unsaved changes, are you sure you want to leave?"
      />
      <BarLoader loading={loading} color={"#36D7B7"} size={150} />
      <PlayerEditor
        selectedSeason={fixture?.season?._id}
        team={teamObj}
        player={selectedPlayer}
        selectPlayer={(p) => {
          setSelectedPlayer(p);
          getData();
        }}
        onCancel={() => {
          setSelectedPlayer();
        }}
        onSave={() => {
          getData();
          setSelectedPlayer();
        }}
      />
      {!loading && (
        <Main>
          <Formation
            team={team}
            fixture={fixture}
            game={game}
            onChange={onChange}
            lineup={lineup}
            squad={squad}
            setLineup={(lineup) => {
              //  updateLineup(lineup);
              onChange(lineup);
            }}
            createPlayer={() => setSelectedPlayer({})}
          />
        </Main>
      )}
    </>
  );
}
