import React, { useState } from "react";
import Grid from "styled-components-grid";
import Input from "../UI/Form/Input";
import styled from "styled-components";
import SelectUI from "../UI/Form/SelectUI";
import { AiOutlineCloudUpload } from "react-icons/ai";

const Swatch = styled.div`
  width: 50px;
  height: 30px;
  border: 1px solid grey;
  border-radius: 3px;
  margin: 0.5em;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;
  overflow: hidden;
`;
const ColourContainer = styled.div`
  display: flex;
  align-items: flex-end;
`;
export default function CompetitionEdit({ competition, onChange }) {
  function updateCompetition(competition) {
    onChange(competition);
  }

  return (
    <Grid style={{ width: "100%" }}>
      <Grid.Unit size={1 / 1}>
        <Input
          value={competition.name}
          title="Name"
          onChange={(e) => {
            let newCompetition = { ...competition, name: e.target.value };
            updateCompetition(newCompetition);
          }}
        />
        <Input
          value={competition.country}
          title="Country"
          onChange={(e) => {
            let newCompetition = { ...competition, country: e.target.value };
            updateCompetition(newCompetition);
          }}
        />
        <Input
          value={competition.order}
          title="Order"
          onChange={(e) => {
            let newCompetition = { ...competition, order: e.target.value };
            updateCompetition(newCompetition);
          }}
        />

        <FileUpload
          id="badge"
          label="Badge"
          value={competition?.badge || ""}
          onChange={(filename) => {
            let newCompetition = { ...competition, badge: filename };
            updateCompetition(newCompetition);
          }}
        />

        <FileUpload
          id="secondaryBadge"
          label="Secondary Badge"
          value={competition?.secondary_badge || ""}
          onChange={(filename) => {
            let newCompetition = { ...competition, secondary_badge: filename };
            updateCompetition(newCompetition);
          }}
        />

        <SelectUI
          title="Competition Type"
          value={
            competition?.competition_type
              ? {
                  value: competition.competition_type,
                  label: competition.competition_type,
                }
              : ""
          }
          options={[
            {
              value: "union",
              label: "union",
            },
            {
              value: "league",
              label: "league",
            },
          ]}
          placeholder="Select format..."
          onChange={(value) => {
            let newCompetition = { ...competition, competition_type: value };
            updateCompetition(newCompetition);
          }}
        />
      </Grid.Unit>
    </Grid>
  );
}

function FileUpload({ value, onChange, label, id }) {
  const [open, setOpen] = useState(false);
  const userId = localStorage.getItem("user-id");
  const project_serevr_url =
    window.ENV?.REACT_APP_PROJECT_SERVER ||
    process.env.REACT_APP_PROJECT_SERVER;

  function handleClose() {
    setOpen(false);
  }

  function upload(e) {
    const files = Array.from(e.target.files);

    const formData = new FormData();

    files.forEach((file, i) => {
      formData.append("file", file);
    });

    fetch(`${project_serevr_url}/api/assets/images/${userId}`, {
      method: "POST",
      body: formData,
    }).then((data) => {
      onChange(files[0].name.replace(/ /g, "_"));
    });
  }
  return (
    <ColourContainer>
      <Input
        value={value}
        title={label}
        onChange={(e) => {
          onChange(e.currentTarget.value);
        }}
      />
      <input
        id={id}
        type="file"
        name="file"
        accept="image/*"
        style={{ display: "none" }}
        onChange={(e) => {
          upload(e);
        }}
      />
      <div>
        <Swatch
          style={{
            backgroundColor: value,
          }}
          onClick={() => document.getElementById(id).click()}
        >
          {!value && <AiOutlineCloudUpload />}
          {value && (
            <img
              style={{ objectFit: "contain", width: "50px", height: "30px" }}
              src={
                project_serevr_url +
                "/api/assets/images/" +
                userId +
                "/" +
                value
              }
              alt="Competition Badge"
            />
          )}
        </Swatch>
      </div>
    </ColourContainer>
  );
}
