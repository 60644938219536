import React from "react";
import styled from "styled-components";
import { Button } from "../../Theme/Hyper";
import SquadList from "./SquadList";

const Main = styled.div`
  display: flex;
  margin-right: 1rem;
  flex-direction: column;
  font-size: 12px;
`;

const Buttons = styled.div`
  display: flex;
  padding: 10px;
`;

const Row = styled.div`
  display: flex;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 100%;
`;

export default function Formation({
  team,
  fixture,
  onChange,
  game,
  lineup,
  squad,
  setLineup,
  createPlayer,
}) {
  function swap(playerId, playerId2, order) {
    if (playerId !== "") {
      let newLineup = [...(JSON.parse(JSON.stringify(lineup)) || [])];
      let player1_order = newLineup.find(
        (p) => p.squad?._id === playerId
      )?.order;
      let player2_order = newLineup.find(
        (p) => p.squad?._id === playerId2
      )?.order;
      if (player1_order && player2_order) {
        newLineup.find((p) => p.squad?._id === playerId).order = player2_order;
        newLineup.find((p) => p.squad?._id === playerId2).order = player1_order;
      } else if (newLineup.find((p) => p.squad?._id === playerId)) {
        newLineup.find((p) => p.squad?._id === playerId).order = order;
      }
      newLineup.sort((a, b) => a.order - b.order);

      setLineup(newLineup);
    }
  }

  function addPlayerAtOrder(playerId, order) {
    let newLineup = [...(lineup || [])];
    newLineup = newLineup.filter((p) => p.order !== order);
    newLineup.push({
      order: order,
      squad: squad.find((s) => s._id === playerId),
    });
    setLineup(newLineup);
    //onChange(newLineup);
  }

  let number_players =
    fixture?.competition?.competition_type === "union" ? 15 : 13;

  return (
    <Main>
      {/* {fixture?.opta_lineup && fixture?.opta_lineup[team]?.player?.length > 0 && (
        <Button
          style={{ marginTop: "20px" }}
          onClick={() => {
            let newLineup = [];
            let sub_order = 12;
            newLineup = fixture?.opta_lineup[team].player
              .map((p, index) => {
                let squadPlayer = squad.find((s) => s.opta_ID == p.playerId);
                if (squadPlayer) {
                  return {
                    ...squadPlayer,
                    order: parseInt(p.formationPlace) || sub_order++,
                  };
                }
              })
              .filter((i) => i != undefined);

            setLineup(newLineup);
            //onChange(newLineup);
          }}
        >
          Load from OPTA
        </Button>
      )} */}
      {team && (
        <Row>
          <Column>
            <Buttons>
              <Button
                onClick={() => {
                  createPlayer();
                }}
              >
                Create Player
              </Button>
            </Buttons>
            <SquadList
              showOrder={true}
              squad={squad
                ?.filter((l) => l.type === "player" && l.active === true)
                ?.filter((s) => {
                  return (
                    !lineup ||
                    lineup.findIndex((l) => l.squad?._id === s._id) === -1
                  );
                })
                ?.sort((a, b) => {
                  var textA = a.shirt_number || 999;
                  var textB = b.shirt_number || 999;
                  return textA - textB;
                })}
              selectPlayer={(player) => {
                let newLineup = [...(lineup || [])];
                let nextOrder = 1;

                newLineup
                  .sort((a, b) => a.order - b.order)
                  .some((p, index) => {
                    if (index === 0 && p.order > 1) {
                      return true;
                    }
                    if (
                      index !== 0 &&
                      p.order - newLineup[index - 1].order > 1
                    ) {
                      return true;
                    }

                    nextOrder++;
                    return false;
                  });

                newLineup.push({ order: nextOrder, ...{ squad: player } });

                setLineup(newLineup);
                //onChange(newLineup);
              }}
              addPlayerAtOrder={() => {}}
              swap={() => {}}
            />
          </Column>
          <Column>
            <Buttons>
              <Button
                onClick={() => {
                  let newLineup = [];
                  setLineup(newLineup);
                  //onChange(newLineup);
                }}
              >
                CLEAR ALL
              </Button>
              <Button
                onClick={() => {
                  let newLineup = lineup.filter(
                    (l) => l.order > number_players
                  );
                  setLineup(newLineup);
                  //onChange(newLineup);
                }}
              >
                CLEAR {number_players}
              </Button>
              <Button
                onClick={() => {
                  let newLineup = lineup.filter(
                    (l) => l.order <= number_players
                  );
                  setLineup(newLineup);
                  // onChange(newLineup);
                }}
              >
                CLEAR SUBS
              </Button>
            </Buttons>
            {
              <>
                <SquadList
                  number_players={number_players}
                  showHeader={true}
                  style={{ overflowY: "auto" }}
                  showOrder={true}
                  showCaptain={true}
                  squad={new Array(number_players)
                    .fill(1)
                    .map((item, index) => {
                      return (
                        (lineup &&
                          lineup.length > 0 &&
                          lineup.find((l) => l.order === index + 1)) || {
                          order: index + 1,
                        }
                      );
                    })}
                  selectPlayer={(player) => {
                    let newLineup = [...lineup].filter(
                      (l) => l.squad?._id !== player?.squad?._id
                    );

                    setLineup(newLineup);
                    // onChange(newLineup);
                  }}
                  selectCaptain={(player, checked) => {
                    let newLineup = [...lineup].map((l) => ({
                      ...l,
                      captain: false,
                    }));
                    let index = newLineup.findIndex(
                      (l) => l.squad?._id === player?.squad?._id
                    );
                    newLineup[index].captain = checked;
                    setLineup(newLineup);
                    // onChange(newLineup);
                  }}
                  swap={swap}
                  addPlayerAtOrder={addPlayerAtOrder}
                />
                <div
                  style={{
                    marginTop: "1em",
                  }}
                >
                  Substitutes
                  <SquadList
                    number_players={number_players}
                    showHeader={false}
                    style={{
                      height: "30vh",
                      overflowY: "auto",
                    }}
                    showOrder={true}
                    squad={new Array(
                      lineup && lineup.length > number_players + 9
                        ? lineup.length - number_players
                        : 9
                    )
                      .fill(1)
                      .map((item, index) => {
                        return (
                          (lineup &&
                            lineup.length > 0 &&
                            lineup.find(
                              (l) => l.order === index + (number_players + 1)
                            )) || {
                            order: index + (number_players + 1),
                          }
                        );
                      })}
                    selectPlayer={(player) => {
                      let newLineup = [...lineup].filter(
                        (l) => l.squad?._id !== player?.squad?._id
                      );

                      setLineup(newLineup);
                      //onChange(newLineup);
                    }}
                    swap={swap}
                    addPlayerAtOrder={addPlayerAtOrder}
                  />
                </div>
              </>
            }
          </Column>
        </Row>
      )}
    </Main>
  );
}
